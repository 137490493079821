import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { WindowRef } from './window.service';
import { Notification } from '../../models/notification.model';
import { Game } from '../../core/constants/game.enum';
import { MainHttp } from '../../core/services/main-http.service';
import { ApiEndpoints } from '../../core/constants/ApiEndpoints';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class CommonService {
    private notificationSubject: ReplaySubject<Notification> = new ReplaySubject<Notification>(1);

    public inputFocusSubject: Subject<any> = new Subject<any>();
    public videoEnded$: Subject<boolean> = new Subject<boolean>();
    public notification$: Observable<Notification> = this.notificationSubject.asObservable();

    constructor(
        private router: Router,
        private http: MainHttp,
        private window: WindowRef,
        @Inject(DOCUMENT) private document
    ) {}

    /**
     * Sends an event that focuses an input from the focus directive.
     *
     * @returns {Observable<any>}
     */
    public focusInput(): Observable<any> {
        return this.inputFocusSubject.asObservable();
    }

    public reduceSectionsToVideoGuides(sections: any[]): any[] {
        return sections.reduce((videoGuides, section) => {
            return videoGuides.concat(section.videoGuides);
        }, []);
    }

    public getCardBrandImage(brand: string) {
        return `/assets/images/card-brands/${brand.toLowerCase()}.svg`;
    }

    public formatExpirationDate(paymentMethod: any) {
        const month: number = paymentMethod.expMonth,
            year: number = paymentMethod.expYear,
            formattedMonth = month < 10 ? `0${month}` : `${month}`,
            formattedYear = `${year % 2000}`;
        return `${formattedMonth}/${formattedYear}`;
    }

    /**
     * Receives and emits a notification
     *
     * @param notification
     */
    public emitNotification(notification: Notification) {
        return this.notificationSubject.next(notification);
    }

    /**
     * Fires notification action and emits the notification through the notification subject.
     * @param {Notification} notification
     * @param {Game} game
     * @returns {any}
     */
    public onNotificationClicked(notification: Notification, game: Game) {
        const coursePermalink = notification.item.coursePermalink;
        const videoGuidePermalink = notification.item.permalink;
        const route = `/${game}/course/${coursePermalink}/${videoGuidePermalink}`;

        if (this.router.url !== route) {
            return this.router.navigate([route]).then(response => {
                this.window.nativeWindow.scrollTo(0, 0);
            });
        }

        return this.emitNotification(notification);
    }

    /**
     * Takes 2 dates and returns the difference between their months.
     * @param date1
     * @param date2
     * @returns {Date | number}
     */
    public getMonthDifferences(date1: Date, date2: Date) {
        let months =
            date2.getMonth() - date1.getMonth() + 12 * (date2.getFullYear() - date1.getFullYear());

        if (date2.getDate() < date1.getDate()) {
            months--;
        }
        return months;
    }

    /**
     * Calls next on the inputFocusSubject
     */
    public emitInputFocus() {
        setTimeout(() => {
            this.inputFocusSubject.next(true);
        });
    }

    public getPercentile(hero, statsName) {
        const heroPercentiles = hero.percentiles[statsName];

        if (!heroPercentiles) {
            return 0;
        }

        const percentilesNames = Object.keys(heroPercentiles);
        const index = percentilesNames.findIndex(
            percentileKey => heroPercentiles[percentileKey] >= hero[statsName]
        );
        const topPercentile = Number(percentilesNames[index]);
        const bottomPercentile = Number(percentilesNames[index - 1]);
        const HIGHEST_PERCENTILE = 99.9;
        const LOWEST_PERCENTILE = 1;

        // if there is no percentile higher than current hero stats value
        if (index === -1) {
            return HIGHEST_PERCENTILE;
        } else if (typeof percentilesNames[index - 1] === 'undefined') {
            return LOWEST_PERCENTILE;
        }

        return (topPercentile + bottomPercentile) / 2;
    }

    public getHeroesWithPercentiles(heroes: any[]): any[] {
        return heroes.map(hero => {
            const heroStats = Object.keys(hero.percentiles || {});
            const percentiles = heroStats.reduce((accumulator, stat) => {
                accumulator[stat] = this.getPercentile(hero, stat);
                return accumulator;
            }, {});

            return { ...hero, percentiles: percentiles };
        });
    }

    public sanitizeTagSlug(slug: string) {
        return slug.replace('-', '');
    }

    public isEmailTaken(email: string): Observable<{ taken: boolean }> {
        return this.http.post(ApiEndpoints.emailAvailable, { email: email });
    }

    /**
     * Check if any, all or some of an element's bounding is out of the viewport.
     * @param element
     */
    public isOutOfView(
        element: HTMLElement
    ): {
        top: boolean;
        right: boolean;
        bottom: boolean;
        left: boolean;
        any: boolean;
        all: boolean;
    } {
        const bounding = element.getBoundingClientRect();

        // Check if it's out of the viewport on each side
        const out = {
            top: false,
            right: false,
            bottom: false,
            left: false,
            any: false,
            all: false
        };
        out.top = bounding.top < 0;
        out.left = bounding.left < 0;
        out.bottom =
            bounding.bottom > (window.innerHeight || this.document.documentElement.clientHeight);
        out.right =
            bounding.right > (window.innerWidth || this.document.documentElement.clientWidth);
        out.any = out.top || out.left || out.bottom || out.right;
        out.all = out.top && out.left && out.bottom && out.right;

        return out;
    }
}
